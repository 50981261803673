<ion-page>
    <ion-loading
        :is-open="loading"
        :message="loadingMessage"
        :translucent="true"
    >
    </ion-loading>
    <div v-if="!settingFound" id="init">
        <Landing @finishConfig="finishConfig"></Landing>
    </div>
    <div v-else>
        <ion-tabs @ionTabsDidChange="afterTabChange">
            <ion-router-outlet></ion-router-outlet>
            <ion-tab-bar slot="bottom" class="scrollable-bar ion-padding-start">
                <ion-tab-button
                    tab="bus"
                    href="/tabs/bus"
                    class="scrollable-item"
                    data-testid="tab-bus"
                >
                    <ion-icon v-if="currentTab == 'bus'" :icon="bus" />
                    <ion-icon v-else :icon="busOutline" />
                    <ion-label class="label-text"
                        >{{ $t('common.bus') }}</ion-label
                    >
                </ion-tab-button>
                <ion-tab-button
                    tab="mtr"
                    href="/tabs/mtr"
                    class="scrollable-item"
                    data-testid="tab-mtr"
                >
                    <BIconTrainFrontFill
                        v-if="currentTab == 'mtr'"
                        class="bi-icons"
                    />
                    <BIconTrainFront v-else class="bi-icons" />
                    <ion-label class="label-text"
                        >{{ $t('common.underground') }}</ion-label
                    >
                </ion-tab-button>
                <ion-tab-button
                    tab="minibus"
                    href="/tabs/minibus"
                    class="scrollable-item"
                    data-testid="tab-minibus"
                >
                    <BIconTruckFrontFill
                        v-if="currentTab == 'minibus'"
                        class="bi-icons"
                    />
                    <BIconTruckFront v-else class="bi-icons" />
                    <ion-label class="label-text"
                        >{{ $t('common.minibus') }}</ion-label
                    >
                </ion-tab-button>
                <ion-tab-button
                    tab="tram"
                    href="/tabs/tram"
                    class="scrollable-item"
                    data-testid="tab-tram"
                >
                    <ion-icon v-if="currentTab == 'tram'" :icon="train" />
                    <ion-icon v-else :icon="trainOutline" />
                    <ion-label class="label-text"
                        >{{ $t('common.tram') }}</ion-label
                    >
                </ion-tab-button>
                <ion-tab-button
                    tab="lightRail"
                    href="/tabs/light-rail"
                    class="scrollable-item"
                    data-testid="tab-light-rail"
                >
                    <BIconTrainLightrailFrontFill
                        v-if="currentTab == 'lightRail'"
                        class="bi-icons"
                    />
                    <BIconTrainLightrailFront v-else class="bi-icons" />
                    <ion-label class="label-text"
                        >{{ $t('common.lightRail') }}</ion-label
                    >
                </ion-tab-button>
                <ion-tab-button
                    tab="ferry"
                    href="/tabs/ferry"
                    class="scrollable-item"
                    data-testid="tab-ferry"
                >
                    <ion-icon v-if="currentTab == 'ferry'" :icon="boat" />
                    <ion-icon v-else :icon="boatOutline" />
                    <ion-label class="label-text"
                        >{{ $t('common.ferry') }}</ion-label
                    >
                </ion-tab-button>
                <!-- <ion-tab-button tab="tab3" href="/tabs/tab3">
	 <ion-icon :icon="square" />
	 <ion-label>Tab 3</ion-label>
	 </ion-tab-button> -->
            </ion-tab-bar>
        </ion-tabs>
    </div>
</ion-page>
